import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "partners"
    }}>{`Partners`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tourisme-lorraine.fr",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Lorraine Tourisme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lorraine-reception.fr/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`lorraine-reception`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.marcotullio-traiteur.com/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`marcotullio-traiteur`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lautrecanalnancy.fr/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`L’autre Canal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.zenith-de-nancy.com/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Zénith de Nancy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.centrepompidou.fr/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Centre Pompidou`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.chateauluneville.meurthe-et-moselle.fr/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Château de Lunéville`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Palais_des_congr%C3%A8s_de_Nancy",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Palais des Congrès`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lerelaisgourmand.fr/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Le relais gourmand`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/LetSphotographie/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`L&S Photographie`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.julienmaria.fr",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Julien Maria Photographie`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/lacasitaemi/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`La Casita D’Emi`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dronedefrance.fr/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Pictures and Videos / Drone de France`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      